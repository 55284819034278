import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./redux/auth";
import messageReducer from "./redux/message";
import globalReducer from "./redux/global";
import { api } from "./services/measurement.service"
import { adminApi } from "./services/admin.service";
import { miApi } from "./services/mi.result.service";
import { teleconApi } from "./services/telecon.service";

const reducer = {
    auth: authReducer,
    message: messageReducer,
    global: globalReducer,
    [api.reducerPath]: api.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [teleconApi.reducerPath]: teleconApi.reducer,
    [miApi.reducerPath]: miApi.reducer,
}

const store = configureStore({
    reducer: reducer,
    middleware: (getDefault) => getDefault().concat(api.middleware).concat(adminApi.middleware).concat(miApi.middleware).concat(teleconApi.middleware),
    devTools: true,
})

export default store;