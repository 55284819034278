import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EventBus from './common/EventBus';
import { logout } from './redux/auth';
import Login from "./views/Login";
import Register from "./views/Register";
import Home from "./views/Home";
import About from "./views/About";
import Profile from "./views/Profile";
// import BoardUser from "./views/BoardUser";
// import BoardModerator from "./views/BoardModerator";
import BoardAdmin from "./views/BoardAdmin";
// import MeasurementTable from "./views/MeasurementTable";
// import MeasurementPlot from "./views/MeasurementPlot";
import Layout from "./layout";
import { useMemo } from 'react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { themeSettings } from "./theme.js";
import CardioParameters from "./views/CardioParameters";
import BasicData from "./views/CategorizedParameters/BasicData";
import BodyCondition from "./views/CategorizedParameters/BodyCondition";
import CoronaryAngiography from "./views/CategorizedParameters/CoronaryAngiography";
import ElectrophysiologicalStudy from "./views/CategorizedParameters/ElectrophysiologicalStudy";
import HeartScintigraphy from "./views/CategorizedParameters/HeartScintigraphy";
import HolterRecording from "./views/CategorizedParameters/HolterRecording";
import Medications from "./views/CategorizedParameters/Medications";
import TransthoracicEchocardiography from "./views/CategorizedParameters/TransthoracicEchocardiography";
import EnterPatient from "./views/EnterPatient";
import Results from "./views/Results";
import MIForm from "./views/MIForm";
import MITable from "./views/MITable/index.jsx";
import MIStats from "./views/MIStats/index.jsx";
import Telecons from "./views/Telecons";


function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [showModerator, setShowModerator] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showMI, setShowMI] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const refresh = useCallback(() => {
    dispatch(refresh());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowMI(currentUser.roles.includes("ROLE_MI"));
      setShowModerator(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdmin(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowMI(false);
      setShowAdmin(false);
      setShowModerator(false);
    }
  }, [currentUser]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => { EventBus.remove("logout"); };
  }, [logOut]);

  useEffect(() => {
    EventBus.on("refresh", () => {
      refresh();
    });

    return () => { EventBus.remove("refresh"); };
  }, [refresh]);

  useEffect(() => {
    if (currentUser) {
      var now = new Date();
      now.setTime(now.getTime() + (5 * 60 * 1000));

      const expiry = new Date(currentUser.expiresAt);
      var timeOut = null;

      if (now.getTime() > expiry) {
        logOut();
      }
      else {
        const millisTillExpiry = expiry - now;
        // console.log(`Will set timeout for ${millisTillExpiry} milliseconds`)
        timeOut = setTimeout(function () {
          logout();
        }, millisTillExpiry);
      }
    }

    return () => {
      if (timeOut) {
        // console.log("Clearing timeout")
        clearTimeout(timeOut);
      }
    };
  }, [currentUser, logOut, refresh]);


  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route element={<Layout
              showModeratorBoard={showModerator}
              showAdminBoard={showAdmin}
              showMIBoard={showMI}
              currentUser={currentUser}
              logOut={logOut} />}>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<Profile />} />

              <Route path="/miform" element={<MIForm />} />
              <Route path="/mitable" element={<MITable />} />
              <Route path="/mistats" element={<MIStats />} />

              <Route path="/cardioParameters" element={<CardioParameters />} />
              <Route path="/basicData" element={<BasicData />} />
              <Route path="/bodyCondition" element={<BodyCondition />} />
              <Route path="/coronaryAngiography" element={<CoronaryAngiography />} />
              <Route path="/electrophysiologicalStudy" element={<ElectrophysiologicalStudy />} />
              <Route path="/heartScintigraphy" element={<HeartScintigraphy />} />
              <Route path="/holterRecording" element={<HolterRecording />} />
              <Route path="/medications" element={<Medications />} />
              <Route path="/transthoracicEchocardiography" element={<TransthoracicEchocardiography />} />
              {/* <Route path="/user" element={<BoardUser />} /> */}
              {/* <Route path="/mod" element={<BoardModerator />} /> */}
              <Route path="/admin" element={<BoardAdmin />} />
              <Route path="/enter_patient" element={<EnterPatient />} />
              <Route path="/results" element={<Results />} />
              <Route path="/telecons" element={<Telecons />} />
              {/* <Route path="/measurement_table" element={<MeasurementTable />} />
              <Route path="/measurement_plot" element={<MeasurementPlot />} /> */}
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
