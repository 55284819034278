import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import dotenv from 'dotenv';

// dotenv.config({ path: "../.." });

const API_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BACKEND + "telecon/" : process.env.REACT_APP_DEV_BACKEND + "telecon/";

export const teleconApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, { getState }) => {
            const user = JSON.parse(localStorage.getItem("user"));
            if (user && user.accessToken) {
                headers.set("authorization", user.accessToken);
            }
            return headers;
        },
    }),
    reducerPath: "teleconApi",
    tagTypes: ["Telecons"],
    endpoints: (build) => ({
        getAll: build.query({
            query: () => "",
            providesTags: ["Telecons"],
        }),
        add: build.mutation({
            query: ({ date, time }) => ({
                url: "add",
                method: "POST",
                body: { date: date, ...(time && { time: time }) },
            }),
            invalidatesTags: ["Telecons"],
        }),
        addFull: build.mutation({
            query: ({ date, agenda, actionItems, time }) => ({
                url: "add",
                method: "POST",
                body: { date: date, agenda: agenda, actionItems: actionItems, ...(time && { time: time }) },
            }),
            invalidatesTags: ["Telecons"],
        }),
        addAgenda: build.mutation({
            query: ({ id, agenda }) => ({
                url: "agenda",
                method: "PATCH",
                body: { id: id, agenda: agenda },
            }),
            invalidatesTags: ["Telecons"],
        }),
        addActionItems: build.mutation({
            query: ({ id, actionItems }) => ({
                url: "actionItems",
                method: "PATCH",
                body: { id: id, actionItems: actionItems },
            }),
            invalidatesTags: ["Telecons"],
        }),
        sendInvitation: build.mutation({
            query: (id) => ({
                url: "sendInvitation",
                method: "POST",
                body: { id: id },
            }),
            invalidatesTags: ["Telecons"],
        }),
        sendMinutes: build.mutation({
            query: (formData) => ({
                url: "minutes",
                method: "POST",
                body: formData
            }),
            invalidatesTags: ["Telecons"],
        }),
    })
})

export const {
    useGetAllQuery,
    useSendInvitationMutation,
    useAddMutation,
    useAddAgendaMutation,
    useAddActionItemsMutation,
    useAddFullMutation,
    useSendMinutesMutation
} = teleconApi;