import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
  Box,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useGetAllQuery,
  useSendInvitationMutation,
  useAddMutation,
  useAddFullMutation,
  useAddActionItemsMutation,
  useAddAgendaMutation,
  useSendMinutesMutation,
} from "../../services/telecon.service.js";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import axios from "axios";

function pad(num, size = 2) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

const Telecons = () => {
  const theme = useTheme();

  const { data, isLoading } = useGetAllQuery();

  const [sendInvitation] = useSendInvitationMutation();
  const handleSendInvitation = (id) => {
    sendInvitation(id);
  };

  const [addTelecon] = useAddMutation();
  const [addFullTelecon] = useAddFullMutation();
  const handleTeleconAdd = (date, time = false) => {
    addTelecon({ date: date, time: time });
  };
  const handleFullTeleconAdd = (date, agenda, actionItems, time = false) => {
    addFullTelecon({
      date: date,
      agenda: agenda,
      actionItems: actionItems,
      time: time,
    });
  };
  const [open, setOpen] = useState(false);
  const [duplTeleconAgenda, setDuplTeleconAgenda] = useState([]);
  const [duplTeleconActionItems, setDuplTeleconActionItems] = useState([]);
  const [openDupl, setOpenDupl] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleAddTelecon = () => {
    const date = `${selectedDate.$y}-${pad(selectedDate.$M + 1)}-${pad(
      selectedDate.$D
    )}`;
    handleTeleconAdd(date);
    handleClose();
  };
  const handleAddTeleconTime = () => {
    const date = `${selectedDate.$y}-${pad(selectedDate.$M + 1)}-${pad(
      selectedDate.$D
    )}`;
    const time = `${pad(selectedDate.$H)}:${pad(selectedDate.$m)}`;
    handleTeleconAdd(date, time);
    handleClose();
  };
  const handleClickDuplOpen = (row) => {
    setDuplTeleconAgenda(row.agenda);
    setDuplTeleconActionItems(row.actionItems);
    setOpenDupl(true);
  };
  const handleDuplClose = () => {
    setOpenDupl(false);
  };
  const handleDuplTelecon = () => {
    const date = `${selectedDate.$y}-${pad(selectedDate.$M + 1)}-${pad(
      selectedDate.$D
    )}`;
    handleFullTeleconAdd(date, duplTeleconAgenda, duplTeleconActionItems);
    handleDuplClose();
  };
  const handleDuplTeleconTime = () => {
    const date = `${selectedDate.$y}-${pad(selectedDate.$M + 1)}-${pad(
      selectedDate.$D
    )}`;
    const time = `${pad(selectedDate.$H)}:${pad(selectedDate.$m)}`;
    handleFullTeleconAdd(date, duplTeleconAgenda, duplTeleconActionItems, time);
    handleDuplClose();
  };

  const [addAgenda] = useAddAgendaMutation();
  const handleAgendaAdd = (id, agendaSend) => {
    addAgenda({ id: id, agenda: agendaSend });
  };
  const [agendaOpen, setAgendaOpen] = useState(false);
  const [agenda, setAgenda] = useState([]);
  const [agendaItem, setAgendaItem] = useState("");
  const [agendaId, setAgendaId] = useState("");
  const handleClickAgendaOpen = (row) => {
    setAgenda(row.agenda);
    setAgendaId(row._id);
    setAgendaOpen(true);
  };
  const handleAgendaClose = () => {
    setAgendaOpen(false);
  };
  const handleAddAgenda = () => {
    handleAgendaAdd(agendaId, agenda);
    handleAgendaClose();
  };
  const deleteAgenda = (index) => {
    const newAgenda = [...agenda];
    newAgenda.splice(index, 1);
    setAgenda(newAgenda);
  };
  const agendaKeyDown = (e) => {
    if (e.keyCode === 13) {
      const newAgenda = [...agenda];
      newAgenda.push(agendaItem);
      setAgenda(newAgenda);
      setAgendaItem("");
    }
  };

  const [addActionItems] = useAddActionItemsMutation();
  const handleActionItemsAdd = (id, actionItemsSend) => {
    addActionItems({ id: id, actionItems: actionItemsSend });
  };
  const [actionItemsOpen, setActionItemsOpen] = useState(false);
  const [actionItems, setActionItems] = useState([]);
  const [actionItem, setActionItem] = useState("");
  const [actionItemsId, setActionItemsId] = useState("");
  const handleClickActionItemsOpen = (row) => {
    setActionItems(row.actionItems);
    setActionItemsId(row._id);
    setActionItemsOpen(true);
  };
  const handleActionItemsClose = () => {
    setActionItemsOpen(false);
  };
  const handleAddActionItems = () => {
    handleActionItemsAdd(actionItemsId, actionItems);
    handleActionItemsClose();
  };
  const deleteActionItem = (index) => {
    const newActionItems = [...actionItems];
    newActionItems.splice(index, 1);
    setActionItems(newActionItems);
  };
  const actionItemKeyDown = (e) => {
    if (e.keyCode === 13) {
      const newActionItems = [...actionItems];
      newActionItems.push(actionItem);
      setActionItems(newActionItems);
      setActionItem("");
    }
  };

  const [sendMinutes] = useSendMinutesMutation();
  const [fileSel, setFileSel] = useState({});
  const [dialog, setDialog] = useState(false);
  const [teleconMinutes, setTeleconMinutes] = useState("");
  const fileUpload = (event) => {
    event.preventDefault();
    setFileSel(event.target.files[0]);
    setDialog(true);
  };
  const fileAccept = () => {
    const formData = new FormData();
    formData.append(teleconMinutes, fileSel);
    formData.append("id", teleconMinutes);
    setDialog(false);
    sendMinutes(formData);
    // axios.post(
    //   process.env.REACT_APP_DEV_BACKEND + "telecon/" + "minutes",
    //   formData
    // );
  };
  const fileDecline = () => {
    setDialog(false);
  };

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const columns = [
    {
      field: "number",
      headerName: "Number",
      flex: 0.8,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      valueGetter: (value) => {
        return new Date(value.value).toDateString();
      },
    },
    {
      field: "agenda",
      headerName: "Agenda Items",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleClickAgendaOpen(params.row)}
          >
            {params.value.length}
          </Button>
        );
      },
    },
    {
      field: "actionItems",
      headerName: "Action Items",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleClickActionItemsOpen(params.row)}
          >
            {params.value.length}
          </Button>
        );
      },
    },
    {
      field: "inviteSent",
      headerName: "Invite Sent",
      flex: 1,
      valueGetter: (value) => {
        if (!value.value) {
          return "No";
        }
        return "Yes";
      },
    },
    {
      field: "minutesSent",
      headerName: "Minutes Sent",
      flex: 1,
      valueGetter: (value) => {
        if (!value.value) {
          return "No";
        }
        return "Yes";
      },
    },
    {
      field: "_",
      headerName: "Send Invite",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSendInvitation(params.row._id)}
          >
            Send Invite
          </Button>
        );
      },
    },
    {
      field: "_1",
      headerName: "Duplicate on Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickDuplOpen(params.row)}
          >
            Duplicate
          </Button>
        );
      },
    },
    {
      field: "_2",
      headerName: "Send Minutes",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            component="label"
            onClick={() => setTeleconMinutes(params.row._id)}
          >
            Minutes
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={fileUpload}
            />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header
          title="Telecon Management"
          subtitle="Manage the PASIPHAE telecons"
        />
        <Box
          mt={"40px"}
          height={"75vh"}
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={data || []}
            columns={columns}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          sx={{ mt: "0.5rem" }}
        >
          Add Telecon
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Telecon</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Telecon Date & Time (UTC)"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
              sx={{ m: "0.5rem" }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddTelecon} color="primary">
            Add
          </Button>
          <Button onClick={handleAddTeleconTime} color="primary">
            Add With Time
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDupl} onClose={handleDuplClose}>
        <DialogTitle>Duplicate Telecon</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Telecon Date & Time (UTC)"
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
              sx={{ m: "0.5rem" }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDuplClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDuplTelecon} color="primary">
            Duplicate
          </Button>
          <Button onClick={handleDuplTeleconTime} color="primary">
            Duplicate With Time
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={agendaOpen} onClose={handleAgendaClose}>
        <DialogTitle>Add Agenda</DialogTitle>
        <DialogContent>
          <List>
            {agenda.map((value, key) => {
              return (
                <ListItem
                  key={`agendalist-${key}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteAgenda(key)}
                    >
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${key + 1}: ${value}`} />
                </ListItem>
              );
            })}
          </List>
          <TextField
            label="Agenda Item"
            value={agendaItem}
            onChange={(e) => setAgendaItem(e.target.value)}
            onKeyDown={(e) => agendaKeyDown(e)}
            fullWidth
            sx={{ m: "0.5rem" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgendaClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddAgenda} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={actionItemsOpen} onClose={handleActionItemsClose}>
        <DialogTitle>Add Action Items</DialogTitle>
        <DialogContent>
          <List>
            {actionItems.map((value, key) => {
              return (
                <ListItem
                  key={`actionlist-${key}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteActionItem(key)}
                    >
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${key + 1}: ${value}`} />
                </ListItem>
              );
            })}
          </List>
          <TextField
            label="Action Item"
            value={actionItem}
            onChange={(e) => setActionItem(e.target.value)}
            onKeyDown={(e) => actionItemKeyDown(e)}
            fullWidth
            sx={{ m: "0.5rem" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleActionItemsClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddActionItems} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialog} onClose={fileDecline}>
        <DialogTitle>Send Minutes</DialogTitle>
        <DialogContent>
          {`You are about to upload ${fileSel.name} as the minutes for telecon ${teleconMinutes}.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={fileDecline} color="primary">
            Cancel
          </Button>
          <Button onClick={fileAccept} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Telecons;
